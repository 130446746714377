import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { rhythm, scale } from '../utils/typography';

interface Props {
  title: string;
  children?: any;
}

const Container = styled.div`
    margin-left: auto;
    margin-right: auto;
    max-width: ${rhythm(40)};
    padding: ${rhythm(1.5)} ${rhythm(3 / 4)};
  `;

const Layout: React.FC<Props> = ({ title, children }: Props) => {
  const rootPath = `${__PATH_PREFIX__}/`;
  let header;
  //
  // if (location.pathname === rootPath) {
  //   header = (
  //     <h1
  //       style={{
  //         ...scale(1.5),
  //         marginBottom: rhythm(1.5),
  //         marginTop: 0,
  //       }}
  //     >
  //       <Link
  //         style={{
  //           boxShadow: 'none',
  //           textDecoration: 'none',
  //           color: 'inherit',
  //         }}
  //         to="/"
  //       >
  //         {title}
  //       </Link>
  //     </h1>
  //   );
  // } else {
  //   header = (
  //     <h3
  //       style={{
  //         fontFamily: 'Montserrat, sans-serif',
  //         marginTop: 0,
  //       }}
  //     >
  //       <Link
  //         style={{
  //           boxShadow: 'none',
  //           textDecoration: 'none',
  //           color: 'inherit',
  //         }}
  //         to="/"
  //       >
  //         {title}
  //       </Link>
  //     </h3>
  //   );
  // }
  return (
    <Container>
      <main>{children}</main>
    </Container>
  );
};

export default Layout;
